import {todaycount,bscount,totalcount,scount}  from '../reducer/actiontype'
import { apiCall } from './apicall';
export function todaycountapi() {
    return (dispatch) =>
      new Promise((resolve, reject) =>
        apiCall(dispatch, "get", "/admin/today_count")
          .then((res) => {
            
            resolve(
              dispatch({
                type: todaycount,
                api: "dashboard",
                toast: false,
                status: true,
                data: typeof res.data==="string"?0:res.data,
              })
            );
          })
          .catch((err) => {
            // setTimeout(() => dispatch({ type: removesloterror }), 2000);
            resolve(
              dispatch({
                type: todaycount,
                api: "dashboard",
                toast: false,
                status: false,
                data: err,
              })
            );
          })
      );
  }
export function bscountapi() {
    return (dispatch) =>
      new Promise((resolve, reject) =>
        apiCall(dispatch, "get", "/admin/bscount")
          .then((res) => {
            resolve(
              dispatch({
                type: bscount,
                api: "dashboard",
                toast: false,
                status: true,
                data:typeof res.data.count!=="number"?0:res.data.count,
              })
            );
          })
          .catch((err) => {
            // setTimeout(() => dispatch({ type: removesloterror }), 2000);
            resolve(
              dispatch({
                type: bscount,
                api: "dashboard",
                toast: false,
                status: false,
                data: err,
              })
            );
          })
      );
  }
export function totalcountapi() {
    return (dispatch) =>
      new Promise((resolve, reject) =>
        apiCall(dispatch, "get", "/admin/total_count")
          .then((res) => {
            resolve(
              dispatch({
                type: totalcount,
                api: "dashboard",
                toast: false,
                status: true,
                data: typeof res.data!=="number"?0:res.data,
              })
            );
          })
          .catch((err) => {
            // setTimeout(() => dispatch({ type: removesloterror }), 2000);
            resolve(
              dispatch({
                type: totalcount,
                api: "dashboard",
                toast: false,
                status: false,
                data: err,
              })
            );
          })
      );
  }
export function scountapi() {
    return (dispatch) =>
      new Promise((resolve, reject) =>
        apiCall(dispatch, "get", "/admin/stadium/count")
          .then((res) => {
            resolve(
              dispatch({
                type: scount,
                api: "dashboard",
                toast: false,
                status: true,
                data: typeof res.data.count!=="number"?0:res.data.count,
              })
            );
          })
          .catch((err) => {
            // setTimeout(() => dispatch({ type: removesloterror }), 2000);
            resolve(
              dispatch({
                type: scount,
                api: "dashboard",
                toast: false,
                status: false,
                data: err,
              })
            );
          })
      );
  }