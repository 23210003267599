import React, { useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import validate from "../validate";
import { UpdateSlot } from 'service/slot';
import Toast from '../Toastify';
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
function EditSlot({ page, existingdata, dispatch }) {
  const [state, setState] = React.useState({
    from: existingdata?.from,
    to: existingdata?.to,
    id: existingdata?.id,
  });
  const [error, setError] = React.useState({
   from:"",
   to:""
  });
  const [inptype,setInptype]=React.useState({from:"time",to:"time"})
  useEffect(
    () =>
      setState({
        from: existingdata?.from,
        to: existingdata?.to,
        id: existingdata?.id,
      }),
    [existingdata]
  );
  function handleError(valid_error,message){
    if(message){
   setError({...error,[valid_error]:message}); 
    }else
    setError(valid_error);
  }
  function handleChange(e) {
    e.preventDefault();
    validate({[e.target.name]: e.target.value},handleError,inptype)
    setState({ ...state, [e.target.name]: e.target.value });
  }
  function handleSubmit() {
    const err_stat=validate(state,handleError,inptype);
   
    if(!err_stat)
       new Promise((resolve,reject)=>resolve(dispatch(UpdateSlot(state))));
  }
  return (
    <div>
      <Toast apiHit="UpdateSlot" page={page}/>
      {" "}
      <div className="header bg-gradient-info pb-1 pt-3 pt-md-6"></div>
      <div className="header pb-3 pt-5 pt-md-6"></div>{" "}
      <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Edit slot</h3>
              </Col>
              <Col className="text-right" xs="4">
              <Button
                color="danger"
                
                onClick={(e) => page("view")}
                size="sm"
              >
                close
              </Button>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">slot information</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        From
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        placeholder="from"
                        value={state.from}
                        required
                        invalid={error.from!==""&&typeof error.from!=="undefined"&&error.from!==null?true:false}
                        valid={error.from==="success"?true:false}
                        name="from"
                        type="time"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.from==="success"?true:false}>{error.from}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-to">
                        To
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-to"
                        required
                        placeholder="To"
                        type="time"
                        invalid={error.to!==""&&typeof error.to!=="undefined"&&error.to!==null?true:false}
                        valid={error.to==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.to}
                        name="to"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.to==="success"?true:false}>{error.to}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginTop:"15px"}}>
                  <Col lg="6">
                  <Button
                      color="primary"
                      style={{marginTop:"20px"}}
                      onClick={handleSubmit}
                      size="sm"
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>

    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(EditSlot);
