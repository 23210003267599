
import Index from "views/Index.js";
import Login from "views/sporty/Login.js";
import Slot from "views/sporty/slot"
import Stadium from "views/sporty/Stadium"
import Promocode from "views/sporty/Promocode.js"
import enquiries from "views/sporty/Enquiries/enquiries"
import bookedStadiums from "views/sporty/BookedStadiums/bookedStadiums"


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-yellow",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/slot",
    name: "Slot",
    icon: "ni ni-badge text-yellow",
    component: Slot,
    exact:true,
    layout: "/admin",
  },
  {
    path: "/stadium",
    name: "Stadium",
    icon: "ni ni-badge text-yellow",
    component: Stadium,
    exact:true,
    layout: "/admin",
  },
  {
    path: "/bookedStadiums",
    name: "Booked Stadiums",
    icon: "ni ni-building text-yellow",
    component: bookedStadiums,
    layout: "/admin",
  },
  {
    path: "/promocode",
    name: "Promo Code",
    icon: "ni ni-building text-yellow",
    component: Promocode,
    layout: "/admin",
  },
  {
    path: "/enquiries",
    name: "Enquiries",
    icon: "ni ni-building text-yellow",
    component: enquiries,
    layout: "/admin",
  },
  
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  
];
export default routes;
