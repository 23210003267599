import { apiCall } from "./apicall";
import {
    bookedStadium,
  stadiumerror,
  removebookedStadiumerror,
  bookedstadiumrefresh
} from "../reducer/actiontype";


export function Getbookedstadium() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "get","/admin/bookedstadium")
        .then((res) => {
          console.log(res)
          resolve(
            dispatch({
              type: bookedStadium,
              api: "liststadium",
              toast: false,
              status: true,
              data: res.data,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => dispatch(
              { type: removebookedStadiumerror }), 2000);
          resolve(
            dispatch({
              type: stadiumerror,
              api: "liststadium",
              toast: true,
              status: false,
              data: err,
            })
          );
        })
    );
}
export function DeleteBookedStadium(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/bscancel", data)
        .then((res) =>   {
          //  setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
         
            dispatch({
              type: bookedStadium,
              api: "liststadium",
              toast: false,
              status: true,    
              data: "stadium Deleted"})
              resolve(dispatch({ type: bookedstadiumrefresh }))
      })
        .catch((err) =>{
            setTimeout(() => dispatch({ type: removebookedStadiumerror }), 2000);
            resolve(
              dispatch({
                type: stadiumerror,
                api: "liststadium",
                toast: true,
                status: false,
                data: err,
              }))})
    );
}
