import {todaycount,bscount,totalcount,scount}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:""
}
export  function today_count(state=initialState,action){
    switch(action.type){
        case todaycount:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }
    }
export  function bs_count(state=initialState,action){
    switch(action.type){
        case bscount:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }
    }
export  function total_count(state=initialState,action){
    switch(action.type){
        case totalcount:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }
    }
export  function s_count(state=initialState,action){
    switch(action.type){
        case scount:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }
    }