import { authenticate,error } from "./actiontype";

const initialState={status:false,data:[]}

 export default function login(state=initialState,action){
    switch(action.type){
        case authenticate:return {
            status:true,
            payload:action.data
        };
        case error:return {
          api:action.api,
          status:false,
          payload:action.data
        };
        default:return state;
    }
}