
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import thunk from 'redux-thunk';
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/savior.scss";
import reducer from './reducer';
import App from './components/App'
import { Provider } from "react-redux";
import { createStore,applyMiddleware,compose } from "redux";
import { setTokenHeader } from "service/apicall";
import { authenticate } from "reducer/actiontype";
const token=localStorage.getItem("token")

const store = createStore(reducer,compose(applyMiddleware(thunk)));
if(token){
setTokenHeader(token);
store.dispatch({
type:authenticate,
data:token
})
}
ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
      <App></App>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

