export const authenticate="authenticate";
export const error="error";

export const slot="slot";
export const slotrefresh="slotrefresh";
export const sloterror="sloterror"
export const removesloterror="removesloterror"

export const stadium="stadium";
export const stadiumrefresh="stadiumrefresh";
export const stadiumerror="stadiumerror"
export const removestadiumerror="removestadiumerror"


export const bookedStadium="bookedStadium"
export const bookedstadiumrefresh="bookedstadiumrefresh";
export const bookedStadiumerror="bookedStadiumerror"
export const removebookedStadiumerror="removebookedStadiumerror"

export const enquiries="enquiries"
export const enquirieserror="enquirieserror"
export const removeenquirieserror="removeenquirieserror"

export const promocode="promocode"
export const promocoderefresh="promocoderefresh";
export const promocodeerror="promocodeerror"
export const removepromocodeerror="removepromocodeerror"

export const bscount="bscount"
export const todaycount="todaycount"
export const totalcount="totalcount"
export const scount="scount"



export const view="view";