import React, { useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import validate from "../validate";
import { UpdateStadium } from 'service/stadium';
import Toast from '../Toastify';
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
function EditStadium({ page, existingdata, dispatch }) {
  const [state, setState] = React.useState({
    name: existingdata?.name,
        desc: existingdata?.desc,
        price: existingdata?.price,
        photos: existingdata?.photos,
        id: existingdata?.id,
  });
  const [error, setError] = React.useState({
    name: "",
    desc: "",
    price: "",
    photos:""
  });
  const [inptype,setInptype]=React.useState({from:"time",to:"time"})
  useEffect(
    () =>
      setState({
        name: existingdata?.name,
        desc: existingdata?.desc,
        price: existingdata?.price,
        photos: existingdata?.photos,
        id: existingdata?.id,
      }),
    [existingdata]
  );
  function handleError(valid_error,message){
    if(message){
   setError({...error,[valid_error]:message}); 
    }else
    setError(valid_error);
  }
  function handleChange(e) {
    e.preventDefault();
    validate({[e.target.name]: e.target.value},handleError,inptype)
    setState({ ...state, [e.target.name]: e.target.value });
  }
  function handleSubmit() {
    const err_stat=validate(state,handleError,inptype);
   
    if(!err_stat)
       new Promise((resolve,reject)=>resolve(dispatch(UpdateStadium(state))));
  }
  return (
    <div>
      <Toast apiHit="UpdateStadium" page={page}/>
      {" "}
      <div className="header bg-gradient-info pb-1 pt-3 pt-md-6"></div>
      <div className="header pb-3 pt-5 pt-md-6"></div>{" "}
      <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Edit Stadium</h3>
              </Col>
              <Col className="text-right" xs="4">
              <Button
                color="danger"
                
                onClick={(e) => page("view")}
                size="sm"
              >
                close
              </Button>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">stadium information</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Name"
                        value={state.name}
                        required
                        invalid={error.name!==""&&typeof error.name!=="undefined"&&error.name!==null?true:false}
                        valid={error.name==="success"?true:false}
                        name="name"
                        type="text"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.name==="success"?true:false}>{error.name}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    Desc
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter Desc"
                        type="text"
                        invalid={error.desc!==""&&typeof error.desc!=="undefined"&&error.desc!==null?true:false}
                        valid={error.desc==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.desc}
                        name="desc"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.desc==="success"?true:false}>{error.desc}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              <Row> 
              <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    price
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter price"
                        type="number"
                        invalid={error.price!==""&&typeof error.price!=="undefined"&&error.price!==null?true:false}
                        valid={error.price==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.price}
                        name="price"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.price==="success"?true:false}>{error.price}</FormFeedback>
                    </FormGroup>
                  </Col>
              <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    Photos
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter Url"
                        type="text"
                        invalid={error.Photos!==""&&typeof error.Photos!=="undefined"&&error.Photos!==null?true:false}
                        valid={error.Photos==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.photos}
                        name="photos"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.Photos==="success"?true:false}>{error.Photos}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{marginTop:"15px"}}>
                  <Col lg="6">
                  <Button
                      color="primary"
                      style={{marginTop:"20px"}}
                      onClick={handleSubmit}
                      size="sm"
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>

    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(EditStadium);
