
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    Button,
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Form,
    Table,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    FormFeedback,
  } from "reactstrap";
  import { withRouter } from "react-router-dom";
  import { connect } from "react-redux";
  import { GetStadium, DeleteStadium } from "service/stadium";
  import React from "react";
  import Toastify from "../Toastify";
  // core components
  import validate from "../validate"
  import { AddStadium } from "service/stadium";
  import "react-toastify/dist/ReactToastify.css";
  
  const Sportystadium = (props) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [start, setStart] = React.useState(1);
    const [search, setSearch] = React.useState("");
    const [filter, setFiler] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    React.useEffect(() => {
      return props.location.pathname === "/stadium"
        ? props.dispatch(GetStadium())
        : false;
    }, [props.screen, props.refresh]);

      function resetInitial(){
        setState({
          name: "",
          desc: "",
          price: "",
          photos:""
         
        })
      }
    const [state, setState] = React.useState({
        name: "",
        desc: "",
        price: "",
        photos:""
       
      });
      const [error, setError] = React.useState({
        name: "",
        desc: "",
        price: "",
        photos:""  
      });
      const [inptype,setInptype]=React.useState({name:"text",desc:"text",price:"number",photos:"text"})
     function handleError(valid_error,message){
        if(message){
       setError({...error,[valid_error]:message}); 
        }else
        setError(valid_error);
      }
      function handleChange(e) {
        e.preventDefault();
        validate({[e.target.name]: e.target.value},handleError,inptype)
        setState({ ...state, [e.target.name]: e.target.value });
      }
      function handleSubmit() {
        const err_stat=validate(state,handleError,inptype);
        console.log(err_stat);
        if(!err_stat)
        new Promise((resolve, reject) => resolve(props.dispatch(AddStadium(state))))
      }
  
    // const requestSearch = async (searchedVal) => {
    //     const filteredRows = this.state.filterRows.filter((row) => {
    //       var present=false;
    //      row= Object.fromEntries(
    //         Object.entries(row).filter(([key, value]) => key === 'parties_name'||key==='balance'||key==="type"||key==="phone") )
    //       Object.values(row).map(data=>{if((typeof data==="string"||typeof data==="number")&&String(data).toLowerCase().includes(searchedVal.toLowerCase()))present=true; return data;});
    //       return present;
    //     });
    //    await this.setState({partiesList:filteredRows, rowsPerPage: 5,page: 0});
    // };
  
    // const cancelSearch = () => {
    //   this.setState({searched:""});
    //   this.requestSearch(this.state.searched);
    // };
    return (
      <>
        {/* Page content */}
        <Toastify apiHit="liststadium" state={resetInitial}></Toastify>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Add stadium</h3>
              </Col>
              
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">stadium information</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Name"
                        value={state.name}
                        required
                        invalid={error.name!==""&&typeof error.name!=="undefined"&&error.name!==null?true:false}
                        valid={error.name==="success"?true:false}
                        name="name"
                        type="text"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.name==="success"?true:false}>{error.name}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    Desc
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter Desc"
                        type="text"
                        invalid={error.desc!==""&&typeof error.desc!=="undefined"&&error.desc!==null?true:false}
                        valid={error.desc==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.desc}
                        name="desc"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.desc==="success"?true:false}>{error.desc}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              <Row> 
              <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    price
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter price"
                        type="number"
                        invalid={error.price!==""&&typeof error.price!=="undefined"&&error.price!==null?true:false}
                        valid={error.price==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.price}
                        name="price"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.price==="success"?true:false}>{error.price}</FormFeedback>
                    </FormGroup>
                  </Col>
              <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-top">
                    Photos
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-top"
                        required
                        placeholder="Enter Url"
                        type="text"
                        invalid={error.Photos!==""&&typeof error.Photos!=="undefined"&&error.Photos!==null?true:false}
                        valid={error.Photos==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.photos}
                        name="photos"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.Photos==="success"?false:false}>{error.Photos}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{marginTop:"15px"}}>
                  <Col lg="6">
                  <Button
                      color="primary"
                      style={{marginTop:"20px"}}
                      
                      onClick={handleSubmit}
                      size="sm"
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Container className="mt-2" fluid>
          <div className="header  pt-md-3"></div>
          {/* Table */}
          <div className="col">
            <Card className="shadow">
            <CardHeader className="border-0">
                  <h3 className="mb-0">Stadium</h3>
                </CardHeader>
            
              <Table className="align-items-center table-flush" responsive>
                <thead style={{ background: "rgb(10 64 78)", color: "white" }}>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th> 
                    <th scope="col">photos</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof props.stadiumList === "object" &&
                  props.stadiumList.length > 0 ? (
                    props.stadiumList
                      .slice((currentPage - 1) * 5, currentPage * 5)
                      .map((data, index) => (
                        <tr>
                          <td>{(currentPage - 1) * 5 + index + 1}</td>
                          <td>{data.name}</td>
                          <td style={{whiteSpace:"normal",minWidth:"300px"}}> {data.desc}</td>
                         
                          <td>
                          <img src={data.photos} width="150px" height="100px"  />
                            </td>
                        
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={(e) => {
                                    props.page("edit",data);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    new Promise((resolve, reject) =>
                                      resolve(
                                        props.dispatch(
                                          DeleteStadium({
                                            status:
                                              data.status === "Active"
                                                ? "InActive"
                                                : "Active",
                                            id: data.id,
                                          })
                                        )
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr >
                      <td />
                      <td />
                      <td>{typeof props.stadiumList!=="string"&&typeof props.stadiumList==="object"? <Spinner size="lg" color="primary" />:"No Data Found"}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (start > 1 || currentPage !== start) {
                            setCurrentPage(currentPage - 1);
                            return currentPage === start
                              ? setStart(start - 1)
                              : false;
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={currentPage === start ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start)}
                      >
                        {start}
                      </PaginationLink>
                    </PaginationItem>
                    {typeof props.stadiumList === "object" &&
                    Math.ceil(props.stadiumList.length / 5) >= start + 1 ? (
                      <PaginationItem
                        className={currentPage === start + 1 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 1)}
                        >
                          {start + 1} <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    {typeof props.stadiumList === "object" &&
                    Math.ceil(props.stadiumList.length / 5) >= start + 2 ? (
                      <PaginationItem
                        className={currentPage === start + 2 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 2)}
                        >
                          {start + 2}
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (
                            typeof props.stadiumList === "object" &&
                            currentPage !== Math.ceil(props.stadiumList.length / 5)
                          )
                            setCurrentPage(currentPage + 1);
                          return currentPage === start + 2 &&
                            typeof props.stadiumList === "object" &&
                            start+2< Math.ceil(props.stadiumList.length / 5)
                            ? setStart(start + 1)
                            : false;
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Container>
      </>
    );
  };
  
  const mapStateToProps = ({ stadium }) => {
    if (stadium.refresh) {
      return { refresh: true };
    }
    if (stadium.api === "liststadium") {
      if (!stadium.status && stadium.payload) {
        return {
          error: stadium.payload,
          toast: "error",
        };
      }
      if (stadium.status) {
        return {
          stadiumList: stadium?.payload?.data,
        };
      } else {
        return {
          error: stadium?.payload,
        };
      }
    }
  };
  export default withRouter(connect(mapStateToProps)(Sportystadium));
  