// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  Table,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Getslot, Deleteslot,UpdateSlot } from "service/slot";
import React, { useState } from "react";
import Toastify from "../Toastify";
// core components
import validate from "../validate";
import { Addslot } from "service/slot";
import "react-toastify/dist/ReactToastify.css";

const Sportyslot = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [occasion, setOccasion] = React.useState(true);
  const [start, setStart] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [filter, setFiler] = React.useState([]);
  const [updatetime, setUpdateTime] = React.useState({});
  const [timeupdat, setTimeupdat] = useState([]);
  React.useEffect(() => {
    return props.location.pathname === "/slot"
      ? props.dispatch(Getslot())
      : false;
  }, [props.screen, props.refresh]);

  const [state, setState] = React.useState({
    time: "",
    price: "",
    date: "",
  });
  function resetInitial(){
    setState({
      time: "",
      price: "",
      date: "",
    })
  }

  const [days, setDays] = React.useState([]);
  const dayName = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [error, setError] = React.useState({
    time: "",
    price: "",
    date: "",
  });
  const [inptype, setInptype] = React.useState({
    time: "time",
    price: "number",
    date: "date",
  });
  function handleError(valid_error, message) {
    if (message) {
      setError({ ...error, [valid_error]: message });
    } else setError(valid_error);
  }
  function handleChange(e) {
    e.preventDefault();
    validate({ [e.target.name]: e.target.value }, handleError, inptype);
    setState({ ...state, [e.target.name]: e.target.value });
  }
  function handleDaySelect(index, e) {
    // e.preventDefault();
    const dayCheck = days;
    dayCheck[index] = !days[index];
    setDays([...dayCheck]);
    console.log(days);
  }
  function handleSubmit() {
    var data = {};
    // console.log(days);
    if (!occasion) {
      data = {
        ...state,
        day: dayName.filter((data, index) => days[index]).join(","),
      };
      delete data["date"];
    } else {
      data = { ...state };
    }
    const err_stat = validate(state, handleError, inptype);
    if (!err_stat)
      new Promise((resolve, reject) => resolve(props.dispatch(Addslot(data))));
  }
const handleUpdateTime=(index,e)=>{
  e.preventDefault();
  const timeUpdate=updatetime;
  updatetime[index]={[e.target.name]:e.target.value,id:props.slotList[index].id};
  setUpdateTime({...updatetime});
}
  // const requestSearch = async (searchedVal) => {
  //     const filteredRows = this.state.filterRows.filter((row) => {
  //       var present=false;
  //      row= Object.fromEntries(
  //         Object.entries(row).filter(([key, value]) => key === 'parties_name'||key==='balance'||key==="type"||key==="phone") )
  //       Object.values(row).map(data=>{if((typeof data==="string"||typeof data==="number")&&String(data).toLowerCase().includes(searchedVal.toLowerCase()))present=true; return data;});
  //       return present;
  //     });
  //    await this.setState({partiesList:filteredRows, rowsPerPage: 5,page: 0});
  // };

  // const cancelSearch = () => {
  //   this.setState({searched:""});
  //   this.requestSearch(this.state.searched);
  // };
  const timeupdate = (index, e) => {
    e.preventDefault();
    setTimeupdat({ ...timeupdat, [index]: !timeupdat[index] });
  };
  return (
    <>
      {/* Page content */}
      <Toastify state={resetInitial} apiHit="listslot"></Toastify>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Add slot</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <h6 className="heading-small text-muted mb-4">slot information</h6>

            <div className="pl-lg-4">
              {/* <Col lg="10"/> */}
              <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() => setOccasion(true)}
                  size="sm"
                >
                  Date
                </Button>

                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() => setOccasion(false)}
                  size="sm"
                >
                  Day
                </Button>
              </Row>
            </div>

            <div className="pl-lg-4">
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Time
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-from"
                      onChange={(e) => handleChange(e)}
                      value={state.time}
                      required
                      invalid={
                        error.time !== "" &&
                        typeof error.time !== "undefined" &&
                        error.time !== null
                          ? true
                          : false
                      }
                      valid={error.time === "success" ? true : false}
                      name="time"
                      type="time"
                    />
                    <FormFeedback
                      style={{ marginTop: "-10px" }}
                      tooltip
                      valid={error.time === "success" ? true : false}
                    >
                      {error.time}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-to">
                      price
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-to"
                      required
                      placeholder="price"
                      type="number"
                      invalid={
                        error.price !== "" &&
                        typeof error.price !== "undefined" &&
                        error.price !== null
                          ? true
                          : false
                      }
                      valid={error.price === "success" ? true : false}
                      onChange={(e) => handleChange(e)}
                      value={state.price}
                      name="price"
                    />
                    <FormFeedback
                      tooltip
                      style={{ marginTop: "-10px" }}
                      valid={error.price === "success" ? true : false}
                    >
                      {error.price}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                {occasion ? (
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-to">
                        Date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-to"
                        required
                        type="date"
                        invalid={
                          error.date !== "" &&
                          typeof error.date !== "undefined" &&
                          error.date !== null
                            ? true
                            : false
                        }
                        valid={error.date === "success" ? true : false}
                        onChange={(e) => handleChange(e)}
                        value={state.date}
                        name="date"
                      />
                      <FormFeedback
                        tooltip
                        style={{ marginTop: "-10px" }}
                        valid={error.date === "success" ? true : false}
                      >
                        {error.date}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ) : (
                  <Col lg={8}>
                    <Row>
                      {dayName.map((data, index) => (
                        <Col xs="12" lg={2} key={index}>
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              name={index}
                              id={"customCheckRegister" + index}
                              type="checkbox"
                              onClick={(e) => handleDaySelect(index, e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"customCheckRegister" + index}
                            >
                              <span className="text-muted">{data}</span>
                            </label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )}
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col lg="6">
                  <Button
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={handleSubmit}
                    size="sm"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Container className="mt-2" fluid>
        <div className="header  pt-md-3"></div>
        {/* Table */}
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">slot</h3>
            </CardHeader>

            <Table className="align-items-center table-flush" responsive>
              <thead style={{ background: "rgb(10 64 78)", color: "white" }}>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Time</th>
                  <th scope="col">Price</th>
                  <th scope="col">Date</th>
                  <th scope="col">Day</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {typeof props.slotList === "object" &&
                props.slotList.length > 0 ? (
                  props.slotList
                    .slice((currentPage - 1) * 5, currentPage * 5)
                    .map((data, index) => (
                      <tr>
                        <td>{(currentPage - 1) * 5 + index + 1}</td>
                        {!timeupdat[index] ? (
                          <td>
                            {data.time.split(":")[0] < 12
                              ? data.time.split(":")[0] == 0
                                ? "12: " + data.time.split(":")[1] + " AM"
                                : data.time + " AM"
                              : data.time.split(":")[0] == 12
                              ? data.time + " PM"
                              : data.time.split(":")[0] -
                                12 +
                                ":" +
                                data.time.split(":")[1] +
                                " PM"}
                          </td>
                        ) : (
                          <FormGroup>
                            <Input
                              className="form-control-alternative mt-2"
                              id="input-from"
                              onChange={(e) => handleUpdateTime(index,e)}
                              value={updatetime[index]?updatetime[index].time:""}
                              required
                              invalid={
                                error.time !== "" &&
                                typeof error.time !== "undefined" &&
                                error.time !== null
                                  ? true
                                  : false
                              }
                              valid={error.time === "success" ? true : false}
                              name="time"
                              type="time"
                            />
                            <FormFeedback
                              style={{ marginTop: "-10px" }}
                              tooltip
                              valid={error.time === "success" ? true : false}
                            >
                              {error.time}
                            </FormFeedback>
                          </FormGroup>
                        )}
                        <td>{data.price}</td>
                        <td>{data.date}</td>
                        <td style={{ textTransform: "Capitalize" }}>
                          {data.day}
                        </td>
                        <td>
                          {" "}
                          {!timeupdat[index] ? (
                            <Button
                              color="primary"
                              onClick={(e) => timeupdate(index, e)}
                              size="sm"
                            >
                              Edit time
                            </Button>
                          ) : (
                            <Button color="warning" onClick={(e)=>{timeupdate(index,e);props.dispatch(UpdateSlot(updatetime[index]))}} size="sm">
                              Update
                            </Button>
                          )}
                        </td>
                        {/* <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={(e) => {
                                    props.page("edit",data);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    new Promise((resolve, reject) =>
                                      resolve(
                                        props.dispatch(
                                          Deleteslot({
                                            status:
                                              data.status === "Active"
                                                ? "InActive"
                                                : "Active",
                                            id: data.id,
                                          })
                                        )
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td> */}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td colSpan={"10"}>
                      {typeof props.slotList !== "string" &&
                      typeof props.slotList === "object" ? (
                        <Spinner size="lg" color="primary" />
                      ) : (
                        "No Data Found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => {
                        if (start > 1 || currentPage !== start) {
                          setCurrentPage(currentPage - 1);
                          return currentPage === start
                            ? setStart(start - 1)
                            : false;
                        }
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={currentPage === start ? "active" : ""}
                  >
                    <PaginationLink onClick={(e) => setCurrentPage(start)}>
                      {start}
                    </PaginationLink>
                  </PaginationItem>
                  {typeof props.slotList === "object" &&
                  Math.ceil(props.slotList.length / 5) >= start + 1 ? (
                    <PaginationItem
                      className={currentPage === start + 1 ? "active" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => setCurrentPage(start + 1)}
                      >
                        {start + 1} <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  {typeof props.slotList === "object" &&
                  Math.ceil(props.slotList.length / 5) >= start + 2 ? (
                    <PaginationItem
                      className={currentPage === start + 2 ? "active" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => setCurrentPage(start + 2)}
                      >
                        {start + 2}
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => {
                        if (
                          typeof props.slotList === "object" &&
                          currentPage !== Math.ceil(props.slotList.length / 5)
                        )
                          setCurrentPage(currentPage + 1);
                        return currentPage === start + 2 &&
                          typeof props.slotList === "object" &&
                          start + 2 < Math.ceil(props.slotList.length / 5)
                          ? setStart(start + 1)
                          : false;
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ slot }) => {
  if (slot.refresh) {
    return { refresh: true };
  }
  if (slot.api === "listslot") {
    if (!slot.status && slot.payload) {
      return {
        error: slot.payload,
        toast: "error",
      };
    }
    if (slot.status) {
      return {
        slotList: slot?.payload?.data,
      };
    } else {
      return {
        error: slot?.payload,
      };
    }
  }
};
export default withRouter(connect(mapStateToProps)(Sportyslot));
