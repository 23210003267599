import { apiCall } from "./apicall";
import {
  slot,
  removesloterror,
  sloterror,
  slotrefresh,
} from "../reducer/actiontype";


export function Getslot() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "get", "/admin/slot/show")
        .then((res) => {
          resolve(
            dispatch({
              type: slot,
              api: "listslot",
              toast: false,
              status: true,
              data: res.data,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removesloterror }), 2000);
          resolve(
            dispatch({
              type: slot,
              api: "listslot",
              toast: true,
              status: false,
              data: err,
            })
          );
        })
    );
}
export function Addslot(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCall(dispatch, "post", "/admin/slot/add", data)
        .then((res) => {
          // setTimeout(() =>
          //  dispatch({ type: removesloterror }), 2000);
          dispatch({
            type: slot,
            api: "listslot",
            status: true,
            toast: true,
            data: "slot Added",
          });
          resolve(dispatch({ type: slotrefresh }))
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removesloterror }), 2000);
          dispatch({
            type: sloterror,
            api: "listslot",
            status: false,
            toast: true,
            data: err,
          });
        });
    });
}

export function UpdateSlot(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/slot/update", data)
        .then((res) => {
          dispatch({
            type: slot,
            api: "listslot",
            status: true,
            toast: true,
            data: "Updated slot",
          });
          resolve(dispatch({ type: slotrefresh }))})
       .catch((err) =>  {
          setTimeout(() => dispatch({ type: removesloterror }), 2000);
          dispatch({
            type: sloterror,
            api: "listslot",
            status: false,
            toast: true,
            data: err,
          });
        })
    );
}

export function Deleteslot(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/slot/delete", data)
        .then((res) =>   
        { 
          // setTimeout(() => dispatch({ type: removesloterror }), 2000);
        dispatch({
          type: slot,
          api: "listslot",
          status: true,
          toast: true,
          data: "slot Deleted"})
        resolve(dispatch({ type: slotrefresh }))})
        .catch((err) =>{  setTimeout(() => dispatch({ type: removesloterror }), 2000);
        dispatch({
          type: slot,
          api: "listslot",
          status: false,
          toast: true,
          data: err,})})
    );
}
