import Promocode from "./Promocode";
import Editpromocode from "./Editpromocode";
import { connect } from "react-redux";
import React, { useEffect } from "react";
function Promocodee(props) {
  const [view, setView] = React.useState("view");
  useEffect(() => setView("view"), [props.view]);
  const page = (screen, data) => {
    switch (screen) {
      case "view":
        return <Promocode page={handleView}></Promocode>;
      case "edit":
        return <Editpromocode page={handleView} existingdata={data}></Editpromocode>;
      default:
        return <Promocode page={handleView}></Promocode>;
    }
  };
  const handleView = (page, data) => {
    setView({ page: page, data: data });
  };
  return <>{page(view.page, view.data)}</>;
}
const mapStateToProps = (state) => {
  return {
   
  };
};
export default connect(mapStateToProps)(Promocodee);
