import Stadium from "./stadium";
import EditStadium from "./Editstadium";
import { connect } from "react-redux";
import React, { useEffect } from "react";
function StadiumIndex(props) {
  const [view, setView] = React.useState("view");
  useEffect(() => setView("view"), [props.view]);
  const page = (screen, data) => {
    switch (screen) {
      case "view":
        return <Stadium page={handleView}></Stadium>;
      case "edit":
        return <EditStadium page={handleView} existingdata={data}></EditStadium>;
      default:
        return <Stadium page={handleView}></Stadium>;
    }
  };
  const handleView = (page, data) => {
    setView({ page: page, data: data });
  };
  return <>{page(view.page, view.data)}</>;
}
const mapStateToProps = (state) => {
  return {

  };
};
export default connect(mapStateToProps)(StadiumIndex);
