import {slot,slotrefresh,removesloterror,sloterror}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:"slot"
}
export default function slots(state=initialState,action){
    switch(action.type){
        case slot:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        case slotrefresh:return {
            refresh:true,
            api:action.api
        }
        case removesloterror:return initialState
        case sloterror:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }

}