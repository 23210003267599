import {bookedStadium,bookedstadiumrefresh,removebookedStadiumerror,bookedStadiumerror}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:"stadium"
}
export default function bookedStadiums(state=initialState,action){
    switch(action.type){
        case bookedStadium:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        case bookedstadiumrefresh:return {
            refresh:true,
            api:action.api
        }
        case removebookedStadiumerror:return initialState
        case bookedStadiumerror:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }

}