import {promocode,promocodeerror,removepromocodeerror,promocoderefresh}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:"promocode"
}
export default function promocodes(state=initialState,action){
    switch(action.type){
        case promocode:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        case promocoderefresh:return {
            refresh:true,
            api:action.api
        }
        case removepromocodeerror:return initialState
        case promocodeerror:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }

}