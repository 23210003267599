import { apiCall } from "./apicall";
import {
    enquiries,
    enquirieserror,
    removeenquirieserror
} from "../reducer/actiontype";

export default function GetEnqureis() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post","/admin/helpline")
        .then((res) => {
          console.log(res)
          resolve(
            dispatch({
              type: enquiries,
              api: "listenquiry",
              toast: false,
              status: true,
              data: res.data,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => dispatch(
              { type: removeenquirieserror }), 2000);
          resolve(
            dispatch({
              type: enquirieserror,
              api: "listenquiry",
              toast: true,
              status: false,
              data: err,
            })
          );
        })
    );
}