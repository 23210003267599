import { combineReducers } from "redux";
import login from "./login";
import slot from "./slot";
import stadium from "./stadium";
import bookedStadium from "./bookedStadium";
import enquiryservice from "./enquiries";
import promocode from "./promocode";
import {total_count as totalcount,today_count as todaycount,bs_count as bscount,s_count as scount} from "./count";

export default combineReducers({
    stadium,
    slot,
    login,
    bookedStadium,
    totalcount,
    todaycount,
    bscount,
    scount,
    enquiryservice,
    promocode

})