import Slot from "./slot";
import EditSlot from "./editslot";
import { connect } from "react-redux";
import React, { useEffect } from "react";
function SlotIndex(props) {
  const [view, setView] = React.useState("view");
  useEffect(() => setView("view"), [props.view]);
  const page = (screen, data) => {
    switch (screen) {
      case "view":
        return <Slot page={handleView}></Slot>;
      case "edit":
        return <EditSlot page={handleView} existingdata={data}></EditSlot>;
      default:
        return <Slot page={handleView}></Slot>;
    }
  };
  const handleView = (page, data) => {
    setView({ page: page, data: data });
  };
  return <>{page(view.page, view.data)}</>;
}
const mapStateToProps = (state) => {
  return {
   
  };
};
export default connect(mapStateToProps)(SlotIndex);
