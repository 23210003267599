import {stadium,stadiumrefresh,removestadiumerror,stadiumerror}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:"stadium"
}
export default function stadiums(state=initialState,action){
    switch(action.type){
        case stadium:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        case stadiumrefresh:return {
            refresh:true,
            api:action.api
        }
        case removestadiumerror:return initialState
        case stadiumerror:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }

}