import ajv from 'ajv';
export default function validate(inpdata, handleError, inptype) {
  var error = false;
  var errors = {};
  Object.entries(inpdata).map(([key, value], index) => {
    console.log(value);
    switch (inptype[key]) {
      case "text":
        if (value === "") {
          error = true;
          return (errors = { ...errors, [key]: "*required" });
        }
        if (value.length < 4) {
          error = true;
          return (errors = {
            ...errors,
            [key]: "Must have four or more characters",
          });
        }
        break;
      case "time":
        // value=value.split(':')
        if (value === "") {
          error = true;
          return (errors = { ...errors, [key]: "*required" });
        }
        if (value.length < 4) {
          error = true;
          return (errors = {
            ...errors,
            [key]: "Must have four or more characters",
          });
        }
        break;
      case "email":
        if (value === "") {
          error = true;
          return (errors = { ...errors, [key]: "*required" });
        }
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
          error = true;
          return (errors = {
            ...errors,
            [key]: "Invalid Email Address",
          });
        }
        break;
        case "number":
          if (value === "") {
            error = true;
            return (errors = { ...errors, [key]: "*required" });
          }
          // if (value.length!==10) {
          //   error = true;
          //   return (errors = {
          //     ...errors,
          //     [key]: "Invalid Mobile Number",
          //   });
          // }
          break;
    }
    // if(!errors[key])
    // errors = { ...errors, [key]: "success" };
  });
  console.log(errors,error);
  handleError(errors);
  return error;
}
