import { apiCall } from "./apicall";
import {
  stadium,
  removestadiumerror,
  stadiumerror,
  stadiumrefresh,
} from "../reducer/actiontype";


export function GetStadium() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "get", "/admin/stadium/show")
        .then((res) => {
          resolve(
            dispatch({
              type: stadium,
              api: "liststadium",
              toast: false,
              status: true,
              data: res.data,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
          resolve(
            dispatch({
              type: stadium,
              api: "liststadium",
              toast: true,
              status: false,
              data: err,
            })
          );
        })
    );
}
export function AddStadium(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCall(dispatch, "post", "/admin/stadium/add", data)
        .then((res) => {
          // setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
          dispatch({
            type: stadium,
            api: "liststadium",
            status: true,
            toast: true,
            data: "stadium Added",
          });
          resolve(dispatch({ type: stadiumrefresh }))
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
          dispatch({
            type: stadiumerror,
            api: "liststadium",
            status: false,
            toast: true,
            data: err,
          });
        });
    });
}

export function UpdateStadium(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/stadium/update", data)
        .then((res) => {
          setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
          dispatch({
            type: stadium,
            api: "UpdateStadium",
            status: true,
            toast: true,
            data: "Updated stadium",
          });}
        )
        .catch((err) =>  {
          setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
          dispatch({
            type: stadiumerror,
            api: "UpdateStadium",
            status: false,
            toast: true,
            data: err,
          });
        })
    );
}

export function DeleteStadium(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/stadium/delete", data)
        .then((res) =>   {
          //  setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
        dispatch({
          type: stadium,
          api: "liststadium",
          status: true,
          toast: true,
          data: "stadium Deleted"})
        resolve(dispatch({ type: stadiumrefresh }))})
        .catch((err) =>{
            setTimeout(() => dispatch({ type: removestadiumerror }), 2000);
        dispatch({
          type: stadium,
          api: "liststadium",
          status: false,
          toast: true,
          data: err,})})
    );
}
