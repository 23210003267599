import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { BrowserRouter,withRouter, Route, Switch, Redirect } from "react-router-dom";
import React from 'react';
import { useSelector } from "react-redux";

function App() {
    const Authenticate=useSelector(state=>state.login?.status)
  return (
    <Switch>
      <BrowserRouter>
        {Authenticate? (
          <>
            <Route
              path="/"
              render={(props) => <AdminLayout {...props} />}
            />{" "}
            {/* <Redirect from="/" exact={true} to="/index" /> */}
          </>
        ) : (
          <>
            <Route path="/" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/login" />
          </>
        )}
      </BrowserRouter>
    </Switch>
  );
}

export default withRouter(App)