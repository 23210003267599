
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    Button,
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Form,
    Table,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    FormFeedback,
  } from "reactstrap";
  import { withRouter } from "react-router-dom";
  import { connect } from "react-redux";
  import { Getpromo, Deletepromo } from "service/promocode";
  import React from "react";
  import Toastify from "../Toastify";
  // core components
  import validate from "../validate"
  import { Addpromo } from "service/promocode";
  import "react-toastify/dist/ReactToastify.css";
  
  const Promocode = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
    const [start, setStart] = React.useState(1);
    const [search, setSearch] = React.useState("");
    const [filter, setFiler] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    React.useEffect(() => {
      return props.location.pathname === "/promocode"
        ? props.dispatch(Getpromo())
        : false;
    }, [props.screen, props.refresh]);

    const [state, setState] = React.useState({
        date: "",
        promo_code: "",
        discount_price: "",
        expire_date: "",
      });
      const [error, setError] = React.useState({
        date: "",
        promo_code: "",
        discount_price: "",
        expire_date: "",
      });
      function resetInitial(){
        setState({
          date: "",
          promo_code: "",
          discount_price: "",
          expire_date: "",
        })
      }

      const [inptype,setInptype]=React.useState({date:"date",promo_code:"text",discount_price:"number",expire_date:"date",})
     function handleError(valid_error,message){
        if(message){
       setError({...error,[valid_error]:message}); 
        }else
        setError(valid_error);
      }
      function handleChange(e) {
        e.preventDefault();
        validate({[e.target.name]: e.target.value},handleError,inptype)
        setState({ ...state, [e.target.name]: e.target.value });
      }
      function handleSubmit() {
        const err_stat=validate(state,handleError,inptype);
        if(!err_stat)
        new Promise((resolve, reject) => resolve(props.dispatch(Addpromo(state))))
      }
    
    return (
      <>
        {/* Page content */}
        <Toastify state={resetInitial} apiHit="listpromo"></Toastify>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Add Promo</h3>
              </Col>
              
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">Promo information</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        value={state.date}
                        required
                        invalid={error.date!==""&&typeof error.date!=="undefined"&&error.date!==null?true:false}
                        valid={error.date==="success"?true:false}
                        name="date"
                        type="date"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.date==="success"?true:false}>{error.date}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        PROMO CODE
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        value={state.promo_code}
                        required
                        invalid={error.promo_code!==""&&typeof error.promo_code!=="undefined"&&error.promo_code!==null?true:false}
                        valid={error.promo_code==="success"?true:false}
                        name="promo_code"
                        placeholder="Enter Promo Code"
                        type="text"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.promo_code==="success"?true:false}>{error.promo_code}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                       DISCOUNT PRICE
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        value={state.discount_price}
                        required
                        invalid={error.discount_price!==""&&typeof error.discount_price!=="undefined"&&error.discount_price!==null?true:false}
                        valid={error.discount_price==="success"?true:false}
                        name="discount_price"
                        placeholder="Enter Discount Price"
                        type="number"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.discount_price==="success"?true:false}>{error.discount_price}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-to">
                      EXPIRY DATE
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-to"
                        required
                        placeholder="To"
                        type="date"
                        invalid={error.expire_date!==""&&typeof error.expire_date!=="undefined"&&error.expire_date!==null?true:false}
                        valid={error.expire_date==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.expire_date}
                        name="expire_date"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.expire_date==="success"?true:false}>{error.to}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginTop:"15px"}}>
                  <Col lg="6">
                  <Button
                      color="primary"
                      style={{marginTop:"20px"}}
                      
                      onClick={handleSubmit}
                      size="sm"
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Container className="mt-2" fluid>
          <div className="header  pt-md-3"></div>
          {/* Table */}
          <div className="col">
            <Card className="shadow">
            <CardHeader className="border-0">
                  <h3 className="mb-0">Promo Code</h3>
                </CardHeader>
            
              <Table className="align-items-center table-flush" responsive>
                <thead style={{ background: "rgb(10 64 78)", color: "white" }}>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Date</th>
                    <th scope="col">Promo Code</th>
                    <th scope="col">Discount Price</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof props.promoList === "object" &&
                  props.promoList.length > 0 ? (
                    props.promoList
                      .slice((currentPage - 1) * 5, currentPage * 5)
                      .map((data, index) => (
                        <tr>
                          <td>{(currentPage - 1) * 5 + index + 1}</td>
                          <td>{data.date}</td>
                          <td>{data.promo_code}</td>
                          <td>{data.discount_price}</td>
                          <td>{data.expire_date}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={(e) => {
                                    props.page("edit",data);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    new Promise((resolve, reject) =>
                                      resolve(
                                        props.dispatch(
                                          Deletepromo({
                                            status:
                                              data.status === "Active"
                                                ? "InActive"
                                                : "Active",
                                            id: data.id,
                                          })
                                        )
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td colSpan={"10"}>{typeof props.promoList!=="string"&&typeof props.promoList==="object"? <Spinner size="lg" color="primary" />:"No Data Found"}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (start > 1 || currentPage !== start) {
                            setCurrentPage(currentPage - 1);
                            return currentPage === start
                              ? setStart(start - 1)
                              : false;
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={currentPage === start ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start)}
                      >
                        {start}
                      </PaginationLink>
                    </PaginationItem>
                    {typeof props.promoList === "object" &&
                    Math.ceil(props.promoList.length / 5) >= start + 1 ? (
                      <PaginationItem
                        className={currentPage === start + 1 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 1)}
                        >
                          {start + 1} <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    {typeof props.promoList === "object" &&
                    Math.ceil(props.promoList.length / 5) >= start + 2 ? (
                      <PaginationItem
                        className={currentPage === start + 2 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 2)}
                        >
                          {start + 2}
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (
                            typeof props.promoList === "object" &&
                            currentPage !== Math.ceil(props.promoList.length / 5)
                          )
                            setCurrentPage(currentPage + 1);
                          return currentPage === start + 2 &&
                            typeof props.promoList === "object" &&
                            start+2< Math.ceil(props.promoList.length / 5)
                            ? setStart(start + 1)
                            : false;
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Container>
      </>
    );
  };
  
  const mapStateToProps = ({ promocode }) => { 
    const promo= promocode
    if (promo.refresh) {
      return { refresh: true };
    }
    if (promo.api === "listpromo") {
      if (!promo.status && promo.payload) {
        return {
          error: promo.payload,
          toast: "error",
        };
      }
      if (promo.status) {
        return {
          promoList: promo?.payload?.data,
        };
      } else {
        return {
          error: promo?.payload,
        };
      }
    }
  };
  export default withRouter(connect(mapStateToProps)(Promocode));
  