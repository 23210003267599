import { apiCall } from "./apicall";
import {
    promocode,
    removepromocodeerror,
  promocodeerror,
  promocoderefresh,
} from "../reducer/actiontype";


export function Getpromo() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "get", "/admin/promo/view")
        .then((res) => {
          resolve(
            dispatch({
              type: promocode,
              api: "listpromo",
              toast: false,
              status: true,
              data: res.data,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removepromocodeerror }), 2000);
          resolve(
            dispatch({
              type: promocode,
              api: "listpromo",
              toast: true,
              status: false,
              data: err,
            })
          );
        })
    );
}
export function Addpromo(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCall(dispatch, "post", "/admin/promo", data)
        .then((res) => {
          // setTimeout(() =>
          //  dispatch({ type: removesloterror }), 2000);
          dispatch({
            type: promocode,
            api: "listpromo",
            status: true,
            toast: true,
            data: "promocode Added",
          });
          resolve(dispatch({ type: promocoderefresh }))
        })
        .catch((err) => {
          setTimeout(() => dispatch({ type: removepromocodeerror }), 2000);
          dispatch({
            type: promocodeerror,
            api: "listpromo",
            status: false,
            toast: true,
            data: err,
          });
        });
    });
}

export function Updatepromo(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/promo/edit", data)
        .then((res) => {
          setTimeout(() => dispatch({ type: removepromocodeerror }), 2000);
          dispatch({
            type: promocode,
            api: "Updatepromo",
            status: true,
            toast: true,
            data: "Updated promo",
          });}
        )
        .catch((err) =>  {
          setTimeout(() => dispatch({ type: removepromocodeerror }), 2000);
          dispatch({
            type: promocodeerror,
            api: "Updatepromo",
            status: false,
            toast: true,
            data: err,
          });
        })
    );
}

export function Deletepromo(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/promo/del", data)
        .then((res) =>   
        { 
          // setTimeout(() => dispatch({ type: removesloterror }), 2000);
        dispatch({
          type: promocode,
          api: "listpromo",
          status: true,
          toast: true,
          data: "Promo Deleted"})
        resolve(dispatch({ type: promocoderefresh }))})
        .catch((err) =>{  setTimeout(() => dispatch({ type: removepromocodeerror }), 2000);
        dispatch({
          type: promocode,
          api: "listpromo",
          status: false,
          toast: true,
          data: err,})})
    );
}
