import { apiCall } from "./apicall";
import { authenticate, error } from "reducer/actiontype";
import { setTokenHeader } from "service/apicall";

export default function loginapi(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCall(dispatch, "post", "/admin/login", data)
        .then((res) => {
          if(res.data.status){
          setTokenHeader(res.data.token);
          localStorage.setItem("token", res.data.token);
          dispatch({ type: authenticate, data: res.data });
          }else{
            dispatch({ type: error, data: "username or password wrong"})
          }

        })
        .catch((err) => dispatch({ type: error, data: err }))
    );
}
