
import React , { useState } from "react";

import  {Getbookedstadium}   from "service/bookedStadium";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Row,
  Col,  
  Card,
  CardHeader,
  CardFooter,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { connect } from "react-redux";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [start, setStart] = React.useState(1);
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  React.useEffect(() => {
    return props.location.pathname === "/index"
      ? props.dispatch(Getbookedstadium())
      : false;
  }, [props.screen, props.refresh]);
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {/* <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
           
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
               
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Booked Stadiums</h3>
                  </div>
                  <div className="col text-right">
                
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Stadium</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Date</th>
                  <th scope="col">Price</th>
                </tr>
                 
                </thead>
                <tbody>
                {typeof props.stadiumList === "object" &&
                props.stadiumList.length > 0 ? (
                  props.stadiumList
                    .slice((currentPage - 1) * 5, currentPage * 5)
                    .map((data, index) => (
                      <tr>
                        <td>{(currentPage - 1) * 5 + index + 1}</td>
                        <td>{data.stadium.name}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.phoneno}</td>
                        <td>{data.address}</td>
                        <td>{data.from}</td>
                        <td>{data.to}</td>
                        <td>{data.date}</td>
                        <td>{data.stadium.price}</td>                     
                      </tr>
                    ))
                ) : (
                  <tr >
                    <td />
                    <td />
                    <td>{props.fetched!==true && typeof props.stadiumList!=="string"&&typeof props.stadiumList==="object"? <Spinner size="lg" color="primary" />:"No Data Found"}</td>
                  </tr>
                )}
              </tbody>
              </Table>
              <CardFooter className="px-4 py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem>
                    <PaginationLink
                      
                      onClick={(e) => {
                        if (start > 1 || currentPage !== start) {
                          setCurrentPage(currentPage - 1);
                          return currentPage === start
                            ? setStart(start - 1)
                            : false;
                        }
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={currentPage === start ? "active" : ""}
                  >
                    <PaginationLink
                      
                      onClick={(e) => setCurrentPage(start)}
                    >
                      {start}
                    </PaginationLink>
                  </PaginationItem>
                  {typeof props.stadiumList === "object" &&
                  Math.ceil(props.stadiumList.length / 5) >= start + 1 ? (
                    <PaginationItem
                      className={currentPage === start + 1 ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start + 1)}
                      >
                        {start + 1} <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  {typeof props.requestList === "object" &&
                  Math.ceil(props.stadiumList.length / 5) >= start + 2 ? (
                    <PaginationItem
                      className={currentPage === start + 2 ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start + 2)}
                      >
                        {start + 2}
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  <PaginationItem>
                    <PaginationLink
                      
                      onClick={(e) => {
                        if (
                          typeof props.stadiumList === "object" &&
                          currentPage !== Math.ceil(props.stadiumList.length / 5)
                        )
                          setCurrentPage(currentPage + 1);
                        return currentPage === start + 2 &&
                          typeof props.stadiumList === "object" &&
                          start+2< Math.ceil(props.stadiumList.length / 5)
                          ? setStart(start + 1)
                          : false;
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = ({ bookedStadium }) => {
  console.log();
  if (bookedStadium.refresh) {
    return { refresh: true };
  }
  if (bookedStadium.api === "liststadium") {
    if (!bookedStadium.status && bookedStadium.payload) {
      return {
        error: bookedStadium.payload,
        toast: "error",
      };
    }
    if (bookedStadium.status) {
      return {
        stadiumList: bookedStadium?.payload?.data,
        fetched:true
      };
    } else {
      return {
        error: bookedStadium?.payload,
      };
    }
  }
};
export default connect(mapStateToProps)(Index);
