
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
  } from "reactstrap";
  import { withRouter } from "react-router-dom";
  import { connect } from "react-redux";
  import  GetEnqureis   from "service/enquries";
  import React from "react";
  import Toastify from "../Toastify";
  // core components
  
  import "react-toastify/dist/ReactToastify.css";
  
  const Sportyenquiry = (props) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [start, setStart] = React.useState(1);
    React.useEffect(() => {
      return props.location.pathname === "/enquiries"
        ? props.dispatch(GetEnqureis())
        : false;
    }, [props.screen, props.refresh]);
  
    return (
      <>
        {/* Page content */}
        <Toastify apiHit="enquiries"></Toastify>
     
        <Container fluid>
          <div className="header  pt-md-3"></div>
          {/* Table */}
          <div className="col mt-8">
            <Card  className="shadow">
            <CardHeader className="border-0">
                  <h3 className="mb-0">Stadium</h3>
                </CardHeader>
            
              <Table className="align-items-center table-flush" responsive>
                <thead style={{ background: "rgb(10 64 78)", color: "white" }}>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Address</th>
                    </tr>
                </thead>
                <tbody>
                  {typeof props.listenquiry === "object" &&
                  props.listenquiry.length > 0 ? (
                    props.listenquiry
                      .slice((currentPage - 1) * 5, currentPage * 5)
                      .map((data, index) => (
                        <tr>
                          <td>{(currentPage - 1) * 5 + index + 1}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.message}</td>
                        </tr>
                      ))
                  ) : (
                    <tr >
                      <td />
                      <td />
                      {/* <td>{typeof props.listenquiry!=="string"&&typeof props.listenquiry==="object"? <Spinner size="lg" color="primary" />:"No Data Found"}</td> */}
                   <h5 className="mt-3">No Data Found!</h5>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (start > 1 || currentPage !== start) {
                            setCurrentPage(currentPage - 1);
                            return currentPage === start
                              ? setStart(start - 1)
                              : false;
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={currentPage === start ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start)}
                      >
                        {start}
                      </PaginationLink>
                    </PaginationItem>
                    {typeof props.listenquiry === "object" &&
                    Math.ceil(props.listenquiry.length / 5) >= start + 1 ? (
                      <PaginationItem
                        className={currentPage === start + 1 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 1)}
                        >
                          {start + 1} <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    {typeof props.listenquiry === "object" &&
                    Math.ceil(props.listenquiry.length / 5) >= start + 2 ? (
                      <PaginationItem
                        className={currentPage === start + 2 ? "active" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) => setCurrentPage(start + 2)}
                        >
                          {start + 2}
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      false
                    )}
                    <PaginationItem>
                      <PaginationLink
                        
                        onClick={(e) => {
                          if (
                            typeof props.listenquiry === "object" &&
                            currentPage !== Math.ceil(props.listenquiry.length / 5)
                          )
                            setCurrentPage(currentPage + 1);
                          return currentPage === start + 2 &&
                            typeof props.listenquiry === "object" &&
                            start+2< Math.ceil(props.listenquiry.length / 5)
                            ? setStart(start + 1)
                            : false;
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Container>
      </>
    );
  };
  
  const mapStateToProps = ({ enquiryservice }) => {
    console.log();
    if (enquiryservice.refresh) {
      return { refresh: true };
    }
    if (enquiryservice.api === "listenquiry") {
      if (!enquiryservice.status && enquiryservice.payload) {
        return {
          error: enquiryservice.payload,
          toast: "error",
        };
      }
      if (enquiryservice.status) {
        return {
            listenquiry: enquiryservice?.payload?.data,
        };
      } else {
        return {
          error: enquiryservice?.payload,
        };
      }
    }
  };
  export default withRouter(connect(mapStateToProps)(Sportyenquiry));
  