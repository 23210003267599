
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {Getbookedstadium,DeleteBookedStadium}   from "service/bookedStadium";
import React from "react";
import Toastify from "../Toastify";
// core components

import "react-toastify/dist/ReactToastify.css";

const Sportystadium = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [start, setStart] = React.useState(1);
  React.useEffect(() => {
    return props.location.pathname === "/bookedStadiums"
      ? props.dispatch(Getbookedstadium())
      : false;
  }, [props.screen, props.refresh]);

  return (
    <>
      {/* Page content */}
      <Toastify apiHit="bookedstadium"></Toastify>
   
      <Container fluid>
        <div className="header  pt-md-3"></div>
        {/* Table */}
        <div className="col mt-8">
          <Card  className="shadow">
          <CardHeader className="border-0">
                <h3 className="mb-0">Stadium</h3>
              </CardHeader>
          
            <Table className="align-items-center table-flush" responsive>
              <thead style={{ background: "rgb(10 64 78)", color: "white" }}>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Stadium</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Date</th>
                  <th scope="col">Price</th>
                  <th scope="col">Action</th>
                  
                 
                </tr>
              </thead>
              <tbody>
                {typeof props.stadiumList === "object" &&
                props.stadiumList.length > 0 ? (
                  props.stadiumList
                    .slice((currentPage - 1) * 5, currentPage * 5)
                    .map((data, index) => (
                      <tr>
                        <td>{(currentPage - 1) * 5 + index + 1}</td>
                        <td>{data.stadium.name}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.phoneno}</td>
                        <td>{data.address}</td>
                        <td>{data.from}</td>
                        <td>{data.to}</td>
                        <td>{data.date}</td>
                        <td>{data.stadium.price}</td>
                      
                        <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={(e) => {
                                    new Promise((resolve, reject) =>
                                      resolve(
                                        props.dispatch(
                                          DeleteBookedStadium({
                                            status:
                                              data.status === "Active"
                                                ? "InActive"
                                                : "Active",
                                            id: data.id,
                                          })
                                        )
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                      </tr>
                    ))
                ) : (
                  <tr >
                    <td />
                    <td />
                    <td>{props.fetched!==true && typeof props.stadiumList!=="string"&&typeof props.stadiumList==="object"? <Spinner size="lg" color="primary" />:"No Data Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem>
                    <PaginationLink
                      
                      onClick={(e) => {
                        if (start > 1 || currentPage !== start) {
                          setCurrentPage(currentPage - 1);
                          return currentPage === start
                            ? setStart(start - 1)
                            : false;
                        }
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={currentPage === start ? "active" : ""}
                  >
                    <PaginationLink
                      
                      onClick={(e) => setCurrentPage(start)}
                    >
                      {start}
                    </PaginationLink>
                  </PaginationItem>
                  {typeof props.stadiumList === "object" &&
                  Math.ceil(props.stadiumList.length / 5) >= start + 1 ? (
                    <PaginationItem
                      className={currentPage === start + 1 ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start + 1)}
                      >
                        {start + 1} <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  {typeof props.requestList === "object" &&
                  Math.ceil(props.stadiumList.length / 5) >= start + 2 ? (
                    <PaginationItem
                      className={currentPage === start + 2 ? "active" : ""}
                    >
                      <PaginationLink
                        
                        onClick={(e) => setCurrentPage(start + 2)}
                      >
                        {start + 2}
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    false
                  )}
                  <PaginationItem>
                    <PaginationLink
                      
                      onClick={(e) => {
                        if (
                          typeof props.stadiumList === "object" &&
                          currentPage !== Math.ceil(props.stadiumList.length / 5)
                        )
                          setCurrentPage(currentPage + 1);
                        return currentPage === start + 2 &&
                          typeof props.stadiumList === "object" &&
                          start+2< Math.ceil(props.stadiumList.length / 5)
                          ? setStart(start + 1)
                          : false;
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ bookedStadium }) => {
  console.log();
  if (bookedStadium.refresh) {
    return { refresh: true };
  }
  if (bookedStadium.api === "liststadium") {
    if (!bookedStadium.status && bookedStadium.payload) {
      return {
        error: bookedStadium.payload,
        toast: "error",
      };
    }
    if (bookedStadium.status) {
      return {
        stadiumList: bookedStadium?.payload?.data,
        fetched:true
      };
    } else {
      return {
        error: bookedStadium?.payload,
      };
    }
  }
};
export default withRouter(connect(mapStateToProps)(Sportystadium));
