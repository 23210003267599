import React, { useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import validate from "../validate";
import { Updatepromo } from 'service/promocode';
import Toast from '../Toastify';
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
function Editpromocode({ page, existingdata, dispatch }) {
  const [state, setState] = React.useState({
    date: existingdata?.date,
    promo_code: existingdata?.promo_code,
    discount_price: existingdata?.discount_price,
    expire_date: existingdata?.expire_date,
    id: existingdata?.id,
  });
  const [error, setError] = React.useState({
    date:"",
    promo_code:"",
    discount_price:"",
    expire_date:""
  });
  const [inptype,setInptype]=React.useState({date:"date",promo_code:"text",discount_price:"number",expire_date:"date"})
  useEffect(
    () =>
      setState({
        date: existingdata?.date,
        promo_code: existingdata?.promo_code,
        discount_price: existingdata?.discount_price,
        expire_date: existingdata?.expire_date,
        id: existingdata?.id,
      }),
    [existingdata]
  );
  function handleError(valid_error,message){
    if(message){
   setError({...error,[valid_error]:message}); 
    }else
    setError(valid_error);
  }
  function handleChange(e) {
    e.preventDefault();
    validate({[e.target.name]: e.target.value},handleError,inptype)
    setState({ ...state, [e.target.name]: e.target.value });
  }
  function handleSubmit() {
    const err_stat=validate(state,handleError,inptype);
   
    if(!err_stat)
       new Promise((resolve,reject)=>resolve(dispatch(Updatepromo(state))));
  }
  return (
    <div>
      <Toast apiHit="Updatepromo" page={page}/>
      {" "}
      <div className="header bg-gradient-info pb-1 pt-3 pt-md-6"></div>
      <div className="header pb-3 pt-5 pt-md-6"></div>{" "}
      <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Edit Promo</h3>
              </Col>
              <Col className="text-right" xs="4">
              <Button
                color="danger"
                
                onClick={(e) => page("view")}
                size="sm"
              >
                close
              </Button>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">Promo information</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        value={state.date}
                        required
                        invalid={error.date!==""&&typeof error.date!=="undefined"&&error.date!==null?true:false}
                        valid={error.date==="success"?true:false}
                        name="date"
                        type="date"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.date==="success"?true:false}>{error.date}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Promo Code
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-from"
                        onChange={(e) => handleChange(e)}
                        placeholder="promo_code"
                        value={state.promo_code}
                        required
                        invalid={error.promo_code!==""&&typeof error.promo_code!=="undefined"&&error.promo_code!==null?true:false}
                        valid={error.promo_code==="success"?true:false}
                        name="promo_code"
                        type="text"
                      />
                      <FormFeedback style={{marginTop:"-10px"}} tooltip valid={error.promo_code==="success"?true:false}>{error.promo_code}</FormFeedback>
                    </FormGroup>
  
                  </Col>
                  
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-to">
                      Discount Price
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-to"
                        required
                        placeholder="discount_price"
                        type="number"
                        invalid={error.discount_price!==""&&typeof error.discount_price!=="undefined"&&error.discount_price!==null?true:false}
                        valid={error.discount_price==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.discount_price}
                        name="discount_price"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.discount_price==="success"?true:false}>{error.discount_price}</FormFeedback>
                    </FormGroup>
                    </Col>
                    <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-to">
                      Expiry Date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-to"
                        required
                        type="date"
                        invalid={error.expire_date!==""&&typeof error.expire_date!=="undefined"&&error.expire_date!==null?true:false}
                        valid={error.expire_date==="success"?true:false}
                        onChange={(e) => handleChange(e)}
                        value={state.expire_date}
                        name="expire_date"
                      />
                        <FormFeedback tooltip style={{marginTop:"-10px"}}  valid={error.expire_date==="success"?true:false}>{error.expire_date}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginTop:"15px"}}>
                  <Col lg="3">
                  <Button
                      color="primary"
                      style={{marginTop:"20px"}}
                      onClick={handleSubmit}
                      size="sm"
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>

    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(Editpromocode);
