
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import React from "react";
import { error } from "../../reducer/actiontype";
import loginapi from "../../service/login";
import { connect } from "react-redux";
const Login = (props) => {
  const [err, setErr] = React.useState("");
  const [state, setState] = React.useState({ password:"",user: "" });
  React.useEffect(()=>{setTimeout(()=>props.dispatch({type:error,payload:""}),2000);setErr(props.Error)},[props.Error])
  return (
    <>
      <Col lg="5" md="7">
      <Card style={{textAlign:"center"}} className="bg-secondary pb-3 shadow border-0">    
                   <CardBody className="px-lg-3 py-lg-3">
                     <i>   
                  </i>  
                       <i> 
                         <img className="ml-2"
                    alt="..."
                    height="80px"
                    width="80px"
                    src={
                      require("../../assets/img/brand/sporty.svg")
                        .default
                    }
                  /></i> </CardBody>
          </Card>                                 
        <div className="mt-2"></div>
        <Card className="bg-secondary shadow border-0">
          
            <>
              <CardHeader className="bg-transparent pb-3">
                <div className="text-muted text-center mt-2 mb-3">
                  <h4>Log in</h4>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fa fa-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        value={state.user}
                        onChange={(event) =>
                          setState({
                            ...state,
                            user: event.target.value,
                          })
                        }
                        autoComplete="new-phone"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fa fa-key "></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Password"
                        type="password"
                        value={state.password}
                        onChange={(event) =>
                          setState({ ...state, password: event.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                
                  <center>
                    <div style={{ color: "red",fontSize:"0.875rem" }}>{err}</div>
                  </center>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      onClick={() =>
                      
                              props.dispatch(
                                loginapi(state)
                               ) }
                      type="button"
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </>
        </Card>
      </Col>
    </>
  );
};
const mapStateToProps = (state) => {
  if(!state.login?.status){
    return {
     Error:state.login?.payload
    }
  
  }
};
export default connect(mapStateToProps)(Login);
