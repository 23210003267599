import axios from "axios";
export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["token"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["token"];
  }
}

export function apiCall(dispatch,method, path, data,params) {

    return new Promise((resolve,reject)=> axios(
        {
            // `url` is the server URL that will be used for the request
            url: path,
          
            // `method` is the request method to be used when making the request
            method: method.toLowerCase(), // default
          
            // `baseURL` will be prepended to `url` unless `url` is absolute.
            // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
            // to methods of that instance.
            baseURL:"https://api.sportyfive.in/",
            // baseURL:"http://api.192.",
        
            // `transformResponse` allows changes to the response data to be made before
            // it is passed to then/catch
            transformResponse: [function (data) {
              // if(typeof data == 'object')
              return JSON.parse(data);
              // else
              return data;
            }],
          
            // `headers` are custom headers to be sent
            headers: {'Content-Type': 'application/json'},
          
            // `params` are the URL parameters to be sent with the request
            // Must be a plain object or a URLSearchParams object
            params: params,
            // `data` is the data to be sent as the request body
            // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
            // When no `transformRequest` is set, must be of one of the following types:
            // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
            // - Browser only: FormData, File, Blob
            // - Node only: Stream, Buffer
            data:data,
          
            // `timeout` specifies the number of milliseconds before the request times out.
            // If the request takes longer than `timeout`, the request will be aborted.
            timeout:0, // default is `0` (no timeout)
          
          }
      ).then((response)=>{resolve(response);}).catch(error=>  {
        if (error.response) {
          if(error.response.status===400 || error.response.data === "invalid data input" ){
            localStorage.clear();
            dispatch({type:error,data:[]});
           window.location.reload();
          }
        reject(error.response.data);
      } else{
        if(typeof error.message==="string"){
          reject(error.message)
        }else if(error.request){
          reject(error.request)
        }else{
          reject(error.message)
        }

      }
      
    }))
        }
