import {enquiries,enquirieserror,removeenquirieserror}  from './actiontype'

const initialState={
    status:false,
    payload:[],
    api:""
}
export default function enquiryservice(state=initialState,action){
    switch(action.type){
        case enquiries:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        case removeenquirieserror:return initialState
        case enquirieserror:return {
            status:action.status,
            payload:action.data,
            toast:action.toast,
            api:action.api
        }
        default:return state
    }

}