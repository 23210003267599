import React from "react";
import { connect } from "react-redux";
import { StaticRouter } from "react-router";
import { admin } from "reducer/actiontype";
import routes from "../../routes";
const { ToastContainer, toast } = require("react-toastify");

function Toast(props) {
  React.useEffect(() => {
    if ((props.error || props.success) && props.api === props.apiHit){
        toast[props.toast](props.error||props.success);
        if(props.success){
        if(props.state)
        props.state()
        setTimeout(()=>props.page?props.page("view"):false,2000);}
    }
  }, [props.error,props.success]);
  return (
    <div>
      <ToastContainer></ToastContainer>
    </div>
  );
}
const mapStateToProps = (state) => {
  console.log(state);
  var page = "";
  for (let i = 0; i < routes.length; i++) {
    if (window.location.pathname.indexOf(routes[i].path) !== -1) {
      page = routes[i].name;
    }
  }
  page=page.split(" ");
  if(page.length>0){
      var temppage="";
      for(let i=0;i<page.length;i++){
          temppage=temppage+page[i][0].toLocaleLowerCase() + page[i].slice(1);
      }
      page=temppage;
  }
  page = page[0].toLocaleLowerCase() + page.slice(1);console.log(state,page);
  if(state[page]){
  
  if (!state[page].status && state[page].payload!==null&&typeof state[page].payload!=='undefined') {
    if (state[page].toast)
      return {
        error: state[page].payload,
        toast: "error",
        api: state[page].api
      };
  } else {
    if (state[page].toast)
      return {
        success: state[page].payload,
        toast: "success",
        api:state[page].api
      };
  };}
  return {}
};
export default connect(mapStateToProps)(Toast);
